<template>
  <div class="lesson" v-loading="pageLoading">
    <div class="title">我的已购</div>
    <div class="pageMain">
      <div class="right">
        <div class="catList">
          <p v-for="(item, index) in catList" :key="index" @click="changeList(item.category_id)" :class="{active: active==item.category_id}">{{ item.category_name }}</p>
        </div>
        <van-list
          v-loading="listLoading"
          v-if="list.length"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
        >
          <div class="list">
            <div class="card" v-for="(item, index) of list" :key="index" @click="jumpDetail(item)">
              <div class="content">
                <van-image class="cover" fit="cover" :src="item.cover">
                  <template v-slot:error>
                    <van-image
                      style="width: 100%; height: 100%"
                      fit="cover"
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
                    ></van-image>
                  </template>
                  <template v-slot:loading>
                    <van-image
                      style="width: 100%; height: 100%"
                      fit="cover"
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"
                    ></van-image>
                  </template>
                </van-image>
                <div class="detail">
                  <p class="title">
                    {{ item.name }}
                  </p>
                  <p class="text">到期时间：{{ item.end_time }}</p>
                  <p class="text">购买时间：{{ item.star_time }}</p>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty class="emtpyMain" v-if="!list.length" description="暂无数据" />
      </div>
      <div class="left">
        <div class="classify" style="margin-bottom: 30px">
          <h2 class="classify-title">热门课程</h2>
          <div
            class="hot-item"
            v-for="(item, index) of topData"
            :key="index"
            @click="jumpDetail2(item)"
          >
            <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="item.is_free == 2" />
              <img :src="$vip" v-else-if="item.is_vip_flat == 1" />
              <img :src="$noVip" v-else />
            </div>
            <van-image
              class="cover"
              :error-icon="$placeImg"
              :loading-icon="$placeImg"
              fit="cover"
              :src="item.cover_mobile"
            />
            <div class="detail">
              <p class="title">{{ item.name }}</p>
              <!-- <p class="subtitle">{{ item.description }}</p> -->
            </div>
          </div>
          <van-empty
            style="margin: 0 auto"
            class="emptySmall"
            v-if="!topData.length"
            description="暂无数据"
          />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import DetailRight from '../components/DetailRight.vue';
export default {
  name: "lesson",
  components:{DetailRight},
  data() {
    return {
      active: -1,
      catList:[],
      list: [],
      topData: [],
      loading: false,
      pageLoading: false,
      finished: true,
      more: false,
      listLoading: false,
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.onLoad);
    this.getData();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {

    async getData() {
      // this.pageLoading = true;

      this.$request.tuoyuCategory({ flat_id: this.plat }).then((res) => {
        console.log(res)
        this.catList = res.data
        this.changeList(this.catList[0].category_id)
      });
      let res2 = await this.$request.tuoyuHotList({
          flat_id: this.plat,
          limit: 3
        });
        this.topData = res2.data.top_list || []
        
     
      
    },
    async changeList(id){
      if(this.active == id) return;
      this.active = id
      let res = await this.$request.tuoyuBuy({category_id:id,flat_id: this.plat})
        this.pageLoading = false;
        if(res.code == 0){
          this.list = res.data.courses
        } else {
          this.$toast(res.msg);
        }
        
    },
    jumpDetail(item) {
      this.$router.push(`./detail2?id=${item.id}`);
    },
    jumpDetail2(item) {
      this.$router.push(`./detail2?id=${item.id}`);
    },
    getMore() {
      if (this.more) {
        return;
      }
      this.more = true;
      if (this.menu == 2) {
        axios
          .get(`/api/platform/pc/category?flat_id=${this.plat}&page=${this.page}`, {})
          .then((res) => {
            this.more = false;
            console.log(res);
            if (res.code == 0) {
              let list = res.data.category[this.classify].goods;
              this.list = this.list.concat(list);
              this.loading = false;
              if (list.length < 10) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            } else {
              this.$toast(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.more = false;
          });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  min-height: calc(100vh - 230px);
  
  overflow: hidden;
  font-family: "SourceHanSans";
  >.title{
    
    font-size: 24px;
    color: #242424;
    font-weight: bold;
    border-bottom: 1px dashed #f0f0f0;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
  }
  .van-sticky {
    left: auto;
    right: auto;
  }
  .pageMain{
    display: flex;
    align-items: flex-start;
  }

  .left {
    margin-left: 40px;
    width: 300px;
    overflow: hidden;
    // margin-top: 20px;
  }

  .classify {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    flex: 0 0 300px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;

    .classify-title {
      font-size: 20px;
      padding: 0 20px 0;
      line-height: 1;
    }

    .classify-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 20px;

      &:hover {
        color: #a40001;
      }
    }

    .active {
      color: #a40001;
      font-weight: 600;
    }
  }

  .hot-title {
    font-size: 22px;
    font-weight: 500;
    padding: 0 20px 15px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .hot-item {
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    .tip {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      img {
        width: 40px;
      }
    }
    &:hover {
      background: #f8f8f8;
    }

    .cover {
      flex: 0 0 84px;
      height: 84px;
      margin-right: 10px;
      ::v-deep img {
        border-radius: 6px;
      }
    }

    .detail {
      flex: auto;

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .right {
    flex: 0 0 860px;
    margin-top: 20px;
    .catList{
      width: 100%;
      margin-bottom: 20px;
      p{
        display: inline-block;
        color: #7f7f7f;
        font-size: 20px;
        cursor: pointer;
        margin-right: 40px;
        &:last-of-type{
          margin-right: 0;
        }
      }
      p.active, p:hover{
        color: #006bb3;
        font-weight: bold;
        // border-bottom: 4px solid #0080d6;
        background: none;
        padding-bottom: 6px
      }
   
    }
  }
  .list{
    overflow: hidden;
  }
  .card {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 400px;
    height: 154px;
    margin-right: 10px;
    float: left;
    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .content {
      display: flex;
      width: 100%;

      .cover {
        margin-right: 15px;
        width: 134px;
        height: 134px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: auto;

        .title {
          font-size: 16px;
          color: #333;
          overflow: hidden;
          display: -webkit-box;
          height: 50px;
          /*弹性伸缩盒子*/
          -webkit-box-orient: vertical;
          /*子元素垂直排列*/
          -webkit-line-clamp: 2;
          /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis;
          margin-bottom: 10px;
          /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
        }

        .text {
          font-size: 14px;
          color: #7f7f7f;
        }
      }

      .handle {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 120px;
      }

      .btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 40px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 30px;
        font-size: 14px;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }
}
</style>

<style lang="scss">
body {
  background: #fff;
}
</style>
